import React, { FC } from 'react';
import useSiteTree, { DEFAULT_EXCLUDED_PAGES, TreeItem } from 'hooks/useSiteTree';

import InfoCard from 'components/elements/InfoCard';
import ListTree from 'components/elements/ListTree';

import { IPropsSitemap } from './models';

import './Sitemap.scss';

const Sitemap: FC<IPropsSitemap> = ({ lang, excludedPages, maxLevelToShow }) => {
  const data: TreeItem[] = useSiteTree({
    lang,
    exclude: [
      ...DEFAULT_EXCLUDED_PAGES,
      ...(excludedPages?.length ? excludedPages.map((item) => item.url) : []),
    ],
  });

  const OrderByPageType: { [key: string]: TreeItem[] } = {
    LDSPage: [],
    ProductListingPage: [],
    SitemapPage: [],
    rest: [],
  };

  data[0]?.children.forEach((item) => {
    if (item?.pageType && OrderByPageType?.[item.pageType]) {
      OrderByPageType[item.pageType].push(item);
    } else {
      OrderByPageType.rest.push(item);
    }
  });

  return data?.[0] ? (
    <InfoCard data-testid="Sitemap" className="sitemap__wrapper">
      <div className="sitemap">
        <ListTree
          currentLevel={0}
          maxLevelToShow={maxLevelToShow}
          items={[
            {
              id: data[0].id,
              path: data[0].path,
              title: data[0].title,
              children: [],
            },
          ]}
        />
        {OrderByPageType.ProductListingPage?.length ? (
          <ListTree
            currentLevel={0}
            maxLevelToShow={maxLevelToShow}
            items={OrderByPageType.ProductListingPage}
          />
        ) : null}

        {OrderByPageType.LDSPage?.length ? (
          <ListTree
            currentLevel={0}
            maxLevelToShow={maxLevelToShow}
            items={OrderByPageType.LDSPage}
          />
        ) : null}

        {OrderByPageType.rest?.length ? (
          <ListTree currentLevel={0} maxLevelToShow={maxLevelToShow} items={OrderByPageType.rest} />
        ) : null}

        {OrderByPageType.SitemapPage?.length ? (
          <ListTree
            currentLevel={0}
            maxLevelToShow={maxLevelToShow}
            items={OrderByPageType.SitemapPage}
          />
        ) : null}
      </div>
    </InfoCard>
  ) : null;
};

export default Sitemap;
