import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import Sitemap from 'components/sections/Sitemap';

import { IPropsSitemapPage } from './models';

import './SitemapPage.scss';

const SitemapPage: FC<IPropsSitemapPage> = ({ data: { pageData }, pageContext }) => {
  const { seo, urls, title, excludedPages, maxLevelToShow } = pageData;
  const { breadcrumbs, lang } = pageContext;

  return (
    <Layout data-testid="SitemapPage" seo={seo} lang={lang} urls={urls} className="sitemap-page">
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <Sitemap lang={lang} excludedPages={excludedPages} maxLevelToShow={maxLevelToShow} />
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: sitemapPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentSitemapPage
    }
  }
`;

export default SitemapPage;
